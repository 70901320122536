import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const commercial = {
  context: 'Service You Can Count On',
  title: 'Reliable, Dendable Commercial HVAC Services',
  description:
    'You depend on your HVAC system to keep your business functioning and your customers comfortable. Choose Cam-Air AC & Heating for your commercial HVAC needs.',
  content: (
    <>
      <p>Services we provide for our commercial customers:</p>
      <ul>
        <li>Commercial HVAC Repair</li>
        <li>Commercial AC Installation and Maintenance</li>
        <li>Air Filtration Solutions</li>
        <li>Thermostat Setup</li>
        <li>Professional Tune Ups</li>
        <li>and more!</li>
      </ul>
      <p>
        If your commercial HVAC unit is malfunctioning or outdated, a new HVAC system could be the best option.
        Commercial units are pushed to their limits on a daily basis. That is why having a reliable commercial HVAC
        service provider is a top priority. Look no further than Cam-Air AC & Heating! We can help you choose the
        perfect HVAC replacement system for you, and we can install it quickly and effectively.
      </p>
    </>
  ),
  subtitle: 'We keep your business running',
  outro:
    'A malfunctioning HVAC system will drive your customers away. With Cam-Air, you can can be sure we will have you up and running again in no time. Contact us today for top-notch commercial HVAC installation services!',
  cta: 'Request Service',
}

const Commercial = () => (
  <Layout>
    <Seo title="Commercial HVAC services for your South Texas business" />

    <div className="py-12 overflow-hidden lg:py-16 bg-gray-50">
      <div className="px-4 mx-auto space-y-8 max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <h2 className="text-base font-semibold tracking-wider uppercase text-primary">{commercial.context}</h2>
          <h3 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 md:text-5xl">{commercial.title}</h3>
        </div>
        <div className="relative z-10 mx-auto text-base max-w-prose lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">{commercial.description}</p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div className="relative z-10">
            <div className="mx-auto prose text-gray-500 prose-indigo lg:max-w-none">
              {commercial.content}
              <h3>{commercial.subtitle}</h3>
              <p>{commercial.outro}</p>
            </div>
            <div className="flex mx-auto mt-10 text-base max-w-prose lg:max-w-none">
              <div className="rounded-md shadow">
                <Link
                  to="/contact"
                  className="inline-flex justify-center px-4 py-2 text-xl font-medium text-white border border-transparent rounded-md shadow-sm lg:text-2xl bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                >
                  {commercial.cta}
                </Link>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-12 text-base max-w-prose lg:mt-0 lg:max-w-none">
            <StaticImage
              className="object-cover w-full bg-white rounded-sm shadow-lg"
              src="../images/person-fixing-commercial-ac.png"
              width={500}
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="person installing ac unit"
              placeholder="none"
              imgStyle={{ objectPosition: 'top' }}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Commercial
